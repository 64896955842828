/**
 * @typedef Product
 * @type {object}
 * @property {string} key - Unique product key.
 * @property {string} theme - The colors theme.
 * @property {string} param - The query param used for onboarding urls.
 * @property {string} searchPrefix - The prefix used for the search API route.
 * @property {string} logo - The product logo path
 */

/**
 * Enum for the products available in the app.
 *
 * @readonly
 * @enum {Record<string, Product>}
 *
 */
export const products = Object.freeze({
  THRIVE: {
    key: 'thrive',
    name: 'Thrive',
    theme: 'dpt',
    param: 'virtual_pt',
    searchPrefix: 'clients',
    logo: '/logos/subbrandlogo_thrive_Primary.svg',
  },
  MOVE: {
    key: 'move',
    name: 'Move',
    theme: 'move',
    param: 'move',
    searchPrefix: 'move-clients',
    logo: '/logos/subbrandlogo_move_Primary.svg',
  },
  BLOOM: {
    key: 'bloom',
    name: 'Bloom',
    theme: 'bloom',
    param: 'bloom',
    searchPrefix: 'bloom-clients',
    logo: '/logos/subbrandlogo_bloom_Primary.svg',
  },
  PLATFORM: {
    key: 'platform',
    name: 'Health',
    theme: 'dpt',
    searchPrefix: 'platform-clients',
    logo: '/sword__colored.svg',
  },
  ON_CALL: {
    key: 'on_call',
    name: 'On-Call',
    theme: 'dpt',
    param: 'on_call',
    app: 'hub',
    searchPrefix: 'on-call-clients',
    logo: '/logos/subbrandlogo_oncall_Primary.svg',
    enrollLabel: 'Get help for free',
  },
  ACADEMY: {
    key: 'academy',
    name: 'Academy',
    theme: 'dpt',
    param: 'academy',
    app: 'academy',
    searchPrefix: 'academy-clients',
    logo: '/logos/subbrandlogo_academy_Primary.svg',
    enrollLabel: 'Watch now for free',
  },
  MIND: {
    key: 'mind',
    name: 'Mind',
    theme: 'mind',
    param: 'mind',
    app: 'mind',
    searchPrefix: 'mind-clients',
    logo: '/logos/subbrandlogo_mind_Primary.svg',
    enrollLabel: 'Watch now for free',
  },
});

export function getProduct({ productKey }) {
  const product = products[productKey.toUpperCase()];

  return product ?? products.PLATFORM;
}
