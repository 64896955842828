import styled from 'styled-components';
import Item from './Links';

const LEGAL_LINKS = [
  {
    label: 'Terms and Conditions',
    href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/terms-and-conditions`,
  },
  {
    label: 'Consent to Telehealth Services',
    href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/consent-to-telehealth-services`,
  },
  {
    label: 'HIPAA Notice of Privacy Practices',
    href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/hipaa-notice-of-privacy-practices`,
  },
  {
    label: 'Privacy Statement',
    href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/privacy-statement`,
  },
  {
    label: 'Non-Discrimination',
    href: `${process.env.NEXT_PUBLIC_SWORD_WEBSITE_URL}/legal/notice-of-non-discrimination`,
  },
];

export default function Legal() {
  return (
    <LinksWrapper>
      {LEGAL_LINKS.map((item) => (
        <Item key={item.href} href={item.href} label={item.label} />
      ))}
      <Item ez-show-preferences="true" label="Cookie Preferences" />
    </LinksWrapper>
  );
}

const LinksWrapper = styled.div`
  display: grid;
  gap: 12px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 32px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: 1fr;
  }
`;
