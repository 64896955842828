export function scrollToSection({ sectionId, isReduced, margin }) {
  const section = document.getElementById(sectionId);

  if (!section) return;

  if (margin) {
    let position = section.getBoundingClientRect();
    window.scrollTo({
      top: position.top + window.scrollY - margin,
      behavior: 'smooth',
    });
  } else {
    section.scrollIntoView({ behavior: isReduced ? 'instant' : 'smooth' });
  }
}

export function scrollToTop({ isReduced }) {
  scroll({ top: 0, behavior: isReduced ? 'instant' : 'smooth' });
}
