import Link from 'next/link';
import styled from 'styled-components';

function Item({ href, label, ...rest }) {
  return (
    <LinkStyle {...rest} href={href} target="_blank" rel="noreferrer" as={href ? Link : 'button'}>
      {label}
    </LinkStyle>
  );
}

const LinkStyle = styled(Link)`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.colors.grey.dark};
  padding-top: 0 !important;

  &:hover {
    text-decoration: underline;
  }
`;

export default Item;
