import { CustomSection, CustomContainer, Content } from './styles';
import HeaderContent from './HeaderContent';
import theme from '@/utils/styles-variables';

const DynamicHeader = ({
  content,
  logo,
  showSwordLogo = true,
  hasContentRight,
  noSpacerBottom,
  userName,
  children,
}) => {
  return (
    <CustomSection
      md="0"
      sm="0"
      colored={theme.colors.neutral.default}
      $noSpacerBottom={noSpacerBottom}
    >
      <CustomContainer $hasContentRight={hasContentRight}>
        <Content>
          <HeaderContent
            content={content}
            logo={logo}
            showSwordLogo={showSwordLogo}
            userName={userName}
          />
        </Content>
        <Content> {children}</Content>
      </CustomContainer>
    </CustomSection>
  );
};

export default DynamicHeader;
